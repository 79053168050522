* {
    margin: 0;
    padding: 0;
}

html {
    scroll-behavior: smooth;
    background-color: $white;
}

body.no-scroll {
    overflow: hidden;
}

.page-holder {
    overflow: hidden;
}

strong {
    font-family: $OnsiteSemiMono-font;
    font-weight: 700;
}

.inner-width {
    width: 670px;
    max-width: 100%;
    margin: 0 auto;
}

/**** Slick Slider ****/
.slick-prev {
    left: -15%;
    top: 47%;
    z-index: 2;

    @media (max-width: 1380px) {
        left: -10%;
    }

    @media (max-width: 991px) {
        left: 0%;
    }

    &::before {
        content: "" !important;
        display: block;
        background: url("../assets/arrow-left.svg") no-repeat center center;
        width: 54px;
        height: 54px;
        background-size: contain;
        margin: 0 auto;

        @media (max-width: 991px) {
            width: 40px;
            height: 40px;
        }

        @media (max-width: 575px) {
            width: 30px;
            height: 30px;
        }
    }
}

.slick-next {
    right: -10%;
    top: 47%;
    @media (max-width: 991px) {
        right: 0%;
    }
    &::before {
        content: "" !important;
        display: block;
        background: url("../assets/arrow-right.svg") no-repeat center center;
        width: 54px;
        height: 54px;
        background-size: contain;
        margin: 0 auto;
        @media (max-width: 991px) {
            width: 40px;
            height: 40px;
        }
        @media (max-width: 575px) {
            width: 30px;
            height: 30px;
        }
    }
}

.slick-dots {
    bottom: -45px;
    li {
        margin: 0 3px;
        width: 12px !important;
        height: 12px !important;
        button {
            width: 12px !important;
            height: 12px !important;
            &::before {
                color: transparent !important;
                opacity: 1 !important;
                border: 1px solid $white;
                border-radius: 50px;
                width: 12px !important;
                height: 12px !important;
                line-height: 12px !important;
            }
        }
    }
    li.slick-active {
        button {
            width: 12px;
            height: 12px;
            background: $white;
            border-radius: 50px;
            &::before {
                color: $white !important;
                opacity: 1;
                font-size: 12px;
                line-height: 12px !important;
            }
        }
    }
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
    opacity: 0;
}

/**** Floating Links ****/
.floating-links {
    position: fixed;
    left: 0;
    bottom: 40%;
    z-index: 10;
    mix-blend-mode: difference;
    color: $white;
    opacity: 1;
    @media (max-width: 1199px) {
        display: none;
    }
    ul {
        list-style: none;
        li {
            margin-bottom: 20px;
            overflow: hidden;
            a {
                font-family: $OnsiteSemiMono-font;
                mix-blend-mode: difference;
                text-transform: uppercase;
                color: $white;
                text-decoration: none;
                font-size: 16px;
                display: inline-block;
                display: flex;
                align-items: center;
                transition: all 0.3s ease-in-out;
                font-weight: 300;
            }
        }
    }
}

/**** Banner Section ****/
.banner-section {
    position: relative;
    overflow: hidden;
    z-index: 0;
    background-color: $black;
    height: 100vh;

    &::after {
        content: "";
        background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0) 16.29%,
            #000000 100%
        );
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 300px;
        @media (max-width: 767px) {
            height: 170px;
        }
    }
    @media (max-width: 991px) {
        height: 100vh;
    }
    .embed-container {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        pointer-events: none;
        overflow: hidden;
        @media (max-width: 575px) {
            position: absolute;
        }
        iframe,
        video {
            object-fit: cover;
            position: absolute;
            inset: 0;
            width: 100%;
            height: 100%;
        }
    }

    .fallback-image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: none; // Hidden by default

        @media (max-width: 767px) {
            display: none; // Show fallback image on mobile
        }
    }

    .fallback-image.show {
        display: block;
    }

    .desktop-video {
        display: block;
        @media (max-width: 575px) {
            display: none;
        }
    }

    .mob-video {
        display: none;
        @media (max-width: 575px) {
            display: block;
        }
    }

    .video-content {
        text-align: center;
        padding: 0 15px;
        position: relative;
        z-index: 1;
        height: 100vh;
        width: max-content;
        margin: 0 auto;
        left: 0;
        right: 0;
        p {
            color: $white;
        }
        .top-desc {
            padding-top: 45px;
            @media (max-width: 767px) {
                padding-top: 15px;
            }
            .bit-title {
                p {
                    font-family: $GesturaHeadline-font;
                }
            }
        }
        .bot-desc {
            position: absolute;
            bottom: 45px;
            left: 0;
            right: 0;
            text-align: center;
            @media (max-width: 767px) {
                bottom: 100px;
            }
            img {
                width: 80px;
                height: 20px;
                object-fit: contain;
                @media (max-width: 1199px) {
                    width: 60px;
                }
                @media (max-width: 767px) {
                    width: 40px;
                }
            }
        }
    }
}

.slider-section,
.model-section,
.desc-section,
.image-section {
    position: relative;
    z-index: 0;
    @media (max-width: 767px) {
        padding-left: 15px;
        padding-right: 15px;
    }
}

/**** Slider Section ****/
.slider-section {
    background-color: $white;
    padding-top: 130px;
    padding-bottom: 130px;
    overflow: hidden;
    @media (max-width: 1199px) {
        padding-top: 110px;
        padding-bottom: 110px;
    }
    @media (max-width: 991px) {
        padding-top: 90px;
        padding-bottom: 90px;
    }
    @media (max-width: 767px) {
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .main-title {
        margin-bottom: 130px;
        @media (max-width: 991px) {
            margin-bottom: 100px;
        }
        @media (max-width: 767px) {
            margin-bottom: 70px;
        }
    }
    .slideshow {
        margin-bottom: 155px;
        @media (max-width: 1199px) {
            margin-bottom: 125px;
        }
        @media (max-width: 991px) {
            margin-bottom: 100px;
        }
        @media (max-width: 767px) {
            margin-bottom: 70px;
        }
        /* the slides */
        .slick-slide {
            margin: 0 15px;
        }

        /* the parent */
        .slick-list {
            margin: 0 -15px;
        }
        img {
            margin: 0 auto;
            height: 960px;
            width: 615px;
            object-fit: contain;
            @media (max-width: 1380px) {
                height: 740px;
                width: 470px;
            }
            @media (max-width: 991px) {
                width: 400px;
                height: 630px;
            }
            @media (max-width: 575px) {
                width: 255px;
                height: auto;
            }
        }
    }
    .desc-box {
        .title {
            margin-bottom: 30px;
        }
        .description {
            p {
                padding-bottom: 35px;
                &:last-of-type {
                    padding-bottom: 0;
                }
            }
        }
    }
    .slider-container {
        position: relative;
    }
}

/**** Modal Section ****/
.model-section,
.model-viewer {
    width: 100%;
    height: 960px;

    @media (max-width: 991px) {
        height: 650px;
    }

    @media (max-width: 575px) {
        height: 440px;
    }
}

.model-section {
    position: unset;

    .container-fluid {
        background-color: $black;
    }
}

.model-viewer {
    width: 100%;
    opacity: 0;
    transition: opacity 2s ease;

    &.loaded {
        opacity: 1;
    }

    &.fullscreen {
        position: fixed;
        inset: 0;
        height: 100vh;
        width: 100vw;
        cursor: grab;
        z-index: 999999;

        // Change cursor to grabbing when dragging
        &:active {
            cursor: grabbing;
        }
    }

    .fullscreen-close {
        position: absolute;
        bottom: 25px;
        z-index: 999;
        right: 25px;
        display: none;
        background-color: transparent;
        border: 0;
        width: 80px;
        height: 80px;

        img {
            width: 60px;
            height: 60px;
            transition: all 0.3s ease-in-out;

            @media (max-width: 767px) {
                width: 30px;
                height: 30px;
            }
        }
        
        &:hover {
            img {
                transform: scale(0.9);
            }
        }

        @media (max-width: 767px) {
            right: 0px;
            bottom: auto;
            top: 0;
        }
    }
}

#loading-message {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    width: max-content;
    animation: pulse 2s infinite;

    p {
        color: $white;
    }
}

@keyframes pulse {
    0% {
        opacity: 0.5;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0.5;
    }
}

.model-custom-cursor-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 999999;
}

.model-custom-cursor {
    position: absolute;
    top: 0;
    left: 0;
    width: 60px;
    height: 60px;
    background-color: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.4);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    pointer-events: none;
    transition: transform 0.05s ease;
    z-index: 999999;
    opacity: 0;

    p {
        color: $white;
        font-size: 11px;
    }

    @media (max-width: 767px) {
        display: none;
    }
}

.model-tap-indicator {
    position: absolute;
    left: 50%;
    bottom: 5%;
    transform: translate(-50%, -50%);
    width: 70px;
    height: 70px;
    background-color: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.4);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    pointer-events: none;
    transition: transform 0.05s ease;
    z-index: 999999;
    color: $white;
    font-size: 11px;
    animation: tap-indicator-pulse 5s infinite;

    @media (min-width: 767px) {
        display: none;
    }
}

@keyframes tap-indicator-pulse {
    0% {
        transform: translate(-50%, -50%) scale(1);
    }

    55% {
        transform: translate(-50%, -50%) scale(1);
    }

    65%{
        transform: translate(-50%, -50%) scale(0.8);
    }

    75% {
        transform: translate(-50%, -50%) scale(1);
    }

    100% {
        transform: translate(-50%, -50%) scale(1);
    }
}

.remove-cursor {
    display: none;
}

.model-custom-cursor.active {
    opacity: 1;
}

.model-section.model-active {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    background-color: $black;
    .scroll-again {
        display: block;
    }
    model-viewer {
        height: 100vh !important;
        margin-top: -60px;
    }
}

.model-mob-heigh {
    height: 100vh !important;
    padding-right: 0 !important;
    padding-left: 0 !important;
}

/**** Image content ****/
.image-section {
    background-color: $white;
    padding-left: 0px;
    padding-right: 0px;
    height: 878px;
    @media (max-width: 1199px) {
        height: 700px;
    }
    @media (max-width: 991px) {
        height: 550px;
    }
    @media (max-width: 767px) {
        height: 450px;
    }
    @media (max-width: 575px) {
        height: 343px;
    }
    .img-box {
        position: relative;
        img {
            width: 100%;
            height: 878px;
            object-fit: cover;
            @media (max-width: 1199px) {
                height: 700px;
            }
            @media (max-width: 991px) {
                height: 550px;
            }
            @media (max-width: 767px) {
                height: 450px;
            }
            @media (max-width: 575px) {
                height: 343px;
            }
        }
        .img-title {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                width: 575px;
                height: 508px;
                object-fit: contain;
                @media (max-width: 1280px) {
                    width: 500px;
                    height: 400px;
                }
                @media (max-width: 991px) {
                    width: 450px;
                    height: 330px;
                }
                @media (max-width: 767px) {
                    width: 325px;
                    height: 250px;
                }
                @media (max-width: 575px) {
                    width: 225px;
                    height: 200px;
                }
            }
        }
    }
}

/**** Description Section ****/
.desc-section {
    background-color: $white;
    padding-bottom: 130px;
    @media (max-width: 1199px) {
        padding-bottom: 110px;
    }
    @media (max-width: 991px) {
        padding-bottom: 90px;
    }
    @media (max-width: 767px) {
        padding-bottom: 50px;
    }
    .model-title {
        width: 985px;
        max-width: 100%;
        margin: 0 auto 100px;
        @media (max-width: 991px) {
            margin: 0 auto 70px;
        }
        @media (max-width: 767px) {
            display: none;
        }
    }
    .title {
        margin-bottom: 50px;
        @media (max-width: 767px) {
            margin-bottom: 30px;
        }
    }
    .description {
        p {
            padding-bottom: 33px;
            &:last-of-type {
                padding-bottom: 0;
            }
        }
    }
}

.desc-one {
    padding-top: 25px;
    @media (max-width: 767px) {
        padding-top: 60px;
    }
}

.desc-two {
    padding-top: 145px;
    @media (max-width: 1199px) {
        padding-top: 110px;
    }
    @media (max-width: 991px) {
        padding-top: 90px;
    }
    @media (max-width: 767px) {
        padding-top: 50px;
    }
}

.floating-links,
.model-section,
.slideshow {
    will-change: transform;
}
