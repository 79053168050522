@font-face {
    font-family: 'GesturaHeadline-Light';
    src: url("../assets/fonts/GesturaHeadline-Light.woff") format("woff"), url("../assets/fonts/GesturaHeadline-Light.woff2") format("woff2");
    font-weight: normal;
    font-style: normal; }

@font-face {
    font-family: 'GesturaDisplayTRIAL-Extralight';
    src: url("../assets/fonts/GesturaDisplayTRIAL-Extralight.woff") format("woff"), url("../assets/fonts/GesturaDisplayTRIAL-Extralight.woff2") format("woff2");
    font-weight: normal;
    font-style: normal; }
    
@font-face {
    font-family: 'OnsiteSemiMono-Light';
        src: url("../assets/fonts/OnsiteSemiMono-Light.woff") format("woff"), url("../assets/fonts/OnsiteSemiMono-Light.woff2") format("woff2");
    font-weight: normal;
    font-style: normal; }

$GesturaHeadline-font: 'GesturaDisplayTRIAL-Extralight';
$OnsiteSemiMono-font: 'OnsiteSemiMono-Light';
