// Default viewport range
@use "sass:math";

$min-vw: 375px;
$max-vw: 1440px;

// SASS Function to calculate responsive font size
@function responsive-font($min-font, $max-font, $min-vw: $min-vw, $max-vw: $max-vw) {
  $viewport-difference: $max-vw - $min-vw;
  $font-difference: $max-font - $min-font;
  $slope: math.div($font-difference, $viewport-difference) * 100vw;
  $base-font: $min-font - math.div($slope * $min-vw, 100vw);

  @return calc(#{$base-font} + #{$slope});
}

// SASS Mixin for responsive typography
@mixin responsive-typography($element, $min-font, $max-font, $min-vw: $min-vw, $max-vw: $max-vw) {
  #{$element} {
    font-size: responsive-font($min-font, $max-font, $min-vw, $max-vw);
    
    @media (max-width: $min-vw) {
      font-size: $min-font;
    }
    
    @media (min-width: $max-vw) {
      font-size: $max-font;
    }
  }
}

// Usage with default range
@include responsive-typography(h1, 40px, 96px);
@include responsive-typography(h2, 24px, 42px);
@include responsive-typography(h3, 24px, 36px);
@include responsive-typography(h4, 18px, 18px);
@include responsive-typography(h5, 16px, 16px);
@include responsive-typography(h6, 14px, 14px);
@include responsive-typography(p, 12px, 18px);

@include responsive-typography('.banner-section .video-content .top-desc .bit-title p', 24px, 37px);
@include responsive-typography('.banner-section .video-content .top-desc .small-title p', 12px, 18px);

/**** Typography ****/
h1 {
  color: $black;
  font-family: $GesturaHeadline-font;
  line-height: 1;
}

h2 {
  color: $black;
  font-family: $GesturaHeadline-font;
  line-height: 1.2;
}

h3 {
  color: $black;
  font-family: $GesturaHeadline-font;
  line-height: 1;
}

h4 {
  color: $black;
  font-family: $GesturaHeadline-font;
}

h5 {
  color: $black;
  font-family: $GesturaHeadline-font;
}

h6 {
  color: $black;
  font-family: $GesturaHeadline-font;
  font-weight: 600;
  text-transform: uppercase;
}

p {
  color: $black;
  font-family: $OnsiteSemiMono-font;
  margin-bottom: 0;
  line-height: 170%;
  font-weight: 300;
  a {
    color: $black;
    font-family: $OnsiteSemiMono-font;
    text-decoration: underline;
    font-weight: 300;
    &:hover {
      text-decoration: none;
      color: $black;
    }
  }
}
