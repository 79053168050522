.col-xs-6{ 
    @media (max-width: 575px) {
        max-width: 50%;
    }
}

.section-order-1 {
    order: 1;
    @media (max-width: 767px) {
        order: 3;
    } 
}

.section-order-2 {
    order: 2;
    @media (max-width: 767px) {
        order: 1;
    } 
}

.section-order-3 {
    order: 3;
    @media (max-width: 767px) {
        order: 2;
    } 
}

.section-order-4 {
    order: 4;
    @media (max-width: 767px) {
        order: 4;
    } 
}

.container {
    @media (max-width: 767px) {
        padding: 0 20px;
    }
}